<template>
  <div class="row">
    <div class="col-xl-6 col-lg-6">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">
                {{ $t("sms.votreSolde") }}
              </p>
              <p class="text-gray-5 mb-0">{{ $t("sms.nbrSmsRestant") }}</p>
            </div>
            <p
              class="text-success font-weight-bold font-size-24 mb-0"
              v-if="solde.sms.solde > 100"
            >
              {{ solde.sms.solde }}
            </p>
            <p class="text-danger font-weight-bold font-size-24 mb-0" v-else>
              {{ solde.sms.solde }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6">
      <div class="card">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">
                {{ $t("sms.nomlExpediteur") }}
              </p>
              <p class="text-gray-5 mb-0">{{ $t("sms.desclExpediteur") }}</p>
            </div>
            <p class="text-success font-weight-bold font-size-24 mb-0">
              {{ solde.sms.senderName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6">
      <h2 class="badge-example">{{ $t("sms.gererListeDistinateurs") }}</h2>

      <div class="card">
        <div class="card-header">
          <div class="cui__utils__heading mb-0">
            <strong>{{ $t("sms.envoyerDesSmsLogins") }}</strong>
          </div>
        </div>
        <div class="card-body">
          <a-form :form="form" class="card-header p-2" @submit="sendSms">
            <div class="row">
              <div class="col-md-12">
                <a-checkbox
                  :checked="checked"
                  @change="handleSelectAll"
                  class="font-weight-bold mb-2"
                >
                  {{ $t("sms.selectAll") }}
                </a-checkbox>
                <div>
                  <label class="typo__label">{{
                    $t("sms.selectionnerClases")
                  }}</label>
                  <multiselect
                    v-model="value"
                    :placeholder="$t('sms.selectionnerClases')"
                    :selectLabel="$t('sms.pressEnterToSelect')"
                    :options="classroomList"
                    :custom-label="customLabel"
                    :close-on-select="false"
                    :preserve-search="true"
                    :maxHeight="700"
                    @input="handleChange"
                    @remove="handleRemove"
                    label="classRoomName"
                    track-by="classRoomName"
                    :preselect-first="true"
                    :multiple="true"
                  >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="align-text-top">
                          {{ props.option.classRoomName }}
                        </span>
                        <br /><span class="option__small font-size-12"
                          >{{ props.option.students.length }}
                          {{ $t("menu.eleves") }}</span
                        >
                      </div>
                    </template>

                    <template slot="noOptions">
                      {{ $t("sms.noOptions") }}
                    </template>
                  </multiselect>
                  <!-- <pre class="language-json"><code>{{ value  }}</code></pre>-->
                </div>
              </div>

              <div class="col-md-12">
                <label class="typo__label">{{ $t("sms.categorieTag") }}</label>
                <br />
                <a-tag color="blue"> Envoyer des logins parents </a-tag>
              </div>
              <div class="col-md-12 mt-3">
                <label class="typo__label"
                  >{{ $t("sms.contenueVotreMessage") }}
                </label>
                <a-form-item>
                  <a-textarea
                    v-model="message"
                    :auto-size="{ minRows: 5, maxRows: 10 }"
                    :disabled="true"
                  />
                </a-form-item>
              </div>
              <div class="col-md-12">
                <a-button
                  type="primary"
                  style="width: 100%"
                  htmlType="submit"
                  size="large"
                  :loading="loadingSms"
                  :disabled="loadingSms"
                  icon="tags"
                  :ghost="false"
                >
                  {{ $t("sms.envoyer") }}
                </a-button>
              </div>
            </div>
          </a-form>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6">
      <h2 class="badge-example">{{ $t("sms.listeFinale") }}</h2>
      <div class="card">
        <div class="card-body">
          <a-alert
            :message="$t('sms.alertNumberSMS')"
            type="info"
            class="mb-3"
            :close-text="$t('recette.fermer')"
          />
          <div :class="$style.head">
            <div :class="$style.headItem" class="mb-3 pr-3">
              <div :class="$style.headIcon" class="bg-light text-dark mr-3">
                <i class="fa fa-comments font-size-18" />
              </div>
              <div>
                <div class="text-uppercase text-muted text-nowrap">
                  {{ $t("sms.nombreSms") }}
                </div>
                <div class="font-weight-bold text-dark">{{ totalSms }}</div>
              </div>
            </div>
            <div :class="$style.headItem" class="mb-3">
              <div :class="$style.headIcon" class="bg-light text-dark mr-3">
                <i class="fa fa-edit font-size-18" />
              </div>
              <div>
                <div class="text-uppercase text-muted text-nowrap">
                  {{ $t("sms.nombreCaracteres") }}
                </div>
                <div class="font-weight-bold text-dark">
                  {{ message.length }}
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <a-checkbox-group
              class="m-2"
              v-model="checkboxValue"
              name="checkboxgroup"
              :options="plainOptions"
              @change="onChange"
            />
            <a-table
              :columns="columns"
              :dataSource="tableData"
              :class="$style.table"
              :pagination="pagination"
            >
              <template slot="parent" slot-scope="text, record">
                <div
                  v-if="
                    checkboxValue.includes('father') ||
                    checkboxValue.includes('mother')
                  "
                >
                  <span v-if="checkboxValue.includes('father')">
                    {{ record.father.firstName + " " + record.father.lastName }}
                  </span>
                  <br />
                  <span v-if="checkboxValue.includes('mother')">
                    {{ record.mother.firstName + " " + record.mother.lastName }}
                  </span>
                </div>
              </template>
              <template slot="phone" slot-scope="text, record">
                <div
                  v-if="
                    checkboxValue.includes('father') ||
                    checkboxValue.includes('mother')
                  "
                >
                  <span
                    v-if="checkboxValue.includes('father')"
                    :class="{
                      'text-danger': record.father.phone.includes('invalide'),
                    }"
                    class="font-weight-bold"
                    >{{ record.father.phone }}</span
                  >
                  <br />
                  <span
                    v-if="checkboxValue.includes('mother')"
                    :class="{
                      'text-danger': record.mother.phone.includes('invalide'),
                    }"
                    class="font-weight-bold"
                    >{{ record.mother.phone }}</span
                  >
                </div>
              </template>
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                  >{{ $t("paiement.chercher") }}</a-button
                >
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                  >{{ $t("action.reinitialiser") }}</a-button
                >
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
const key = "updatable";
export default {
  computed: {
    ...mapState(["settings"]),
  },
  created() {
    this.form = this.$form.createForm(this, { name: "sendSMSForm" });
    //get solde
    this.getSolde();
    apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $set: {
              classRoomName: "$classRoom",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoomName",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              idParent: { $arrayElemAt: ["$parent._id", 0] },
              father: { $arrayElemAt: ["$parent.father", 0] },
              mother: { $arrayElemAt: ["$parent.mother", 0] },
              userName: { $arrayElemAt: ["$parent.userName", 0] },
              pp: { $arrayElemAt: ["$parent.pp", 0] },

              classRoom: 1,
              firstName: 1,
              lastName: 1,
              classRoomName: { $arrayElemAt: ["$classRoomName.name", 0] },
            },
          },
        ],
      })
      .then((res) => {
        const { data } = res;
        this.studentList = data.map((e) => {
          let formatted = { ...e };
          formatted.father.phone = !this.validPhoneNumber(e.father.phone)
            ? e.father.phone + " - invalide"
            : e.father.phone.toString();
          formatted.mother.phone = !this.validPhoneNumber(e.mother.phone)
            ? e.mother.phone + " - invalide"
            : e.mother.phone.toString();
          return formatted;
        });

        // create classroom array
        const classRooms = _.mapValues(
          _.groupBy(this.studentList, "classRoomName"),
          (clist) => clist.map((elem) => _.omit(elem, "classRoomName"))
        );
        delete classRooms["undefined"];

        this.classroomList = [];
        for (const classRoomName of Object.keys(classRooms).sort()) {
          if (classRoomName !== "Non inscrit")
            this.classroomList.push({
              classRoomName,
              students: classRooms[classRoomName],
            });
        }
      })
      .catch((e) => {
        console.log(e);
        this.$message.error("Aucun élève est trouvé");
      })
      .finally(() => (this.loadingStudents = false));
  },
  data() {
    return {
      solde: {
        sms: {
          solde: "--",
          senderName: "EdTrust",
        },
      },
      message:
        "Votre compte parent est activé, Télécharger l'application 'EdParent' ou sur https://parent.edtrust.tn\nNom d'utilisateur: [auto]\nMot de passe : [auto]",
      loadingSms: false,
      loadingStudents: true,
      studentsData: [],
      classroomList: [],
      studentList: [],
      value: [],
      columns: [
        {
          title: this.$t("sms.destinataire"),
          dataIndex: "father",
          key: "parent",
          scopedSlots: {
            customRender: "parent",
          },
        },
        {
          title: this.$t("sms.telephone"),
          dataIndex: "father.phone",
          class: "bg-transparent text-gray-6",
          scopedSlots: {
            customRender: "phone",
          },
        },
      ],
      tableData: [],
      plainOptions: [
        { label: this.$t("sms.pere"), value: "father" },
        { label: this.$t("sms.mere"), value: "mother" },
      ],
      checkboxValue: ["father"],
      totalSms: 0,
      pagination: {
        defaultPageSize: 8,
      },
      checked: false,
    };
  },
  methods: {
    getSolde() {
      apiClient
        .get("/sms/solde")
        .then((res) => {
          this.solde = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(e.response.data.message);
        })
        .finally(() => (this.loadingStudents = false));
    },
    moment,
    getParentPhones() {
      let parentPhones = [];

      this.value.forEach((classe) => {
        parentPhones.push(
          ...classe.students.map((p) => {
            return {
              _id: p.idParent,
              userName: p.userName,
              pp: p.pp,
              father: { phone: p.father.phone },
              mother: { phone: p.mother.phone },
            };
          })
        );
      });
      return parentPhones;
    },
    sendSms(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingSms = true;
          let parentPhones = this.getParentPhones();
          console.log(parentPhones);

          apiClient
            .put("/sms/loginparents", {
              target: this.checkboxValue,
              message: "",
              customNumbers: "",
              total: this.totalSms,
              tags: ["Logins parents"],
              parents: parentPhones,
            })
            .then((res) => {
              this.getSolde();
              this.form.resetFields();
              this.value = [];
              this.tableData = [];
              this.$message.success("SMS envoyé avec succeés");
            })
            .catch((e) => {
              console.log(e);
              switch (e.response.status) {
                case 401:
                  this.$message.warning(e.response.data.message);

                  break;

                default:
                  this.$message.error(e.response.data.message);

                  break;
              }
            })
            .finally(() => (this.loadingSms = false));
        }
      });
    },

    smsNumber() {
      let parentPhones = this.getParentPhones();

      this.$message.loading({ content: "Calculer le nombre d'SMS...", key });

      apiClient
        .post("/sms/loginparents/totalsms", {
          target: this.checkboxValue,
          message: "",
          customNumbers: "",
          total: this.totalSms,
          tags: ["Logins parents"],
          parents: parentPhones,
        })
        .then((res) => {
          this.$message.info({
            content: "Totale des sms : " + res.data.cost,
            key,
            duration: 2,
          });

          this.totalSms = res.data.cost;
        });
    },

    customLabel({ classRoomName }) {
      return `${classRoomName}`;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSelectAll(e) {
      this.checked = e.target.checked;

      this.value = e.target.checked ? [...this.classroomList] : [];
      const students = [];
      this.value.forEach((classe) => {
        students.push(...classe.students);
      });
      this.tableData = students;
      this.smsNumber();
    },
    handleChange(value) {
      const students = [];
      value.forEach((classe) => {
        students.push(...classe.students);
      });
      this.tableData = students;
      this.smsNumber();
    },

    handleRemove(removedOption, id) {
      console.log(`selectedOption ${removedOption} , id: ${id}`);
    },
    //a table
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    isArabic(text) {
      let pattern = /[\u0600-\u06FF\u0750-\u077F]/;
      return pattern.test(text);
    },
    //checkbot
    onChange(checkedValues) {
      this.smsNumber();
    },
    validPhoneNumber(phone) {
      const regex = /^([1-9]{1}[0-9]{7})$/;
      if (phone) return phone.toString().trim().match(regex) ? true : false;
      else return false;
    },
  },
};
</script>
  <style lang="scss" module>
@import "./style.module.scss";
</style>
  